import * as React from "react";
import { withPreviewResolver } from "gatsby-source-prismic";

const PreviewPage = ({ isPreview, isLoading }) => {
	if (isPreview === false) return "Not a preview!";

	return (
		<div>
			<p>Loading</p>
		</div>
	);
};

export default withPreviewResolver(PreviewPage, {
	repositoryName: "christianwatson",
	linkResolver: ({ node, key, value }) => (doc) => {
		// 	// Your link resolver
		if (doc.uid === "home") {
			return `/`;
		} else if (doc.type === "article") {
			return `/journal/${doc.uid}`;
		} else if (doc.type === "buying_guide") {
			return `/buying-guides/${doc.uid}`;
		} else if (doc.type === "buying_guides") {
			return `/${doc.uid}`;
		} else if (doc.type === "care_guide") {
			return `/care-guides/${doc.uid}`;
		} else if (doc.type === "care_guides") {
			return `/${doc.uid}`;
		} else if (doc.type === "collection") {
			return `/furniture/${doc.uid}`;
		} else if (doc.type === "limited_edition_collection") {
			return `/limited-edition/${doc.uid}`;
		} else if (doc.type === "product") {
			return `/products/${doc.data.linked_product.handle}`;
		} else if (doc.type === "policy_page") {
			return `/${doc.uid}`;
		} else if (doc.type === "page" && doc.uid !== "home") {
			return `/${doc.uid}`;
		} else {
			return `/${doc.uid}`;
		}
	},
});
